<template>
  <div class="home">
    <div v-if="DISLoading" class="loader">
      <img :src="DISLogo" alt="" />
    </div>
    <!-- <div v-if="stolenItem && !isHZ" class="stolen-message">
      THIS ITEM HAS BEEN REPORTED STOLEN. PLEASE CALL TTC USA 917-971-1815
    </div> -->

    <div v-if="stolenItem" class="stolen-message">
      This item has been REPORTED stolen please remit to any Helzberg Store or
      Call <br />
      1 (800) 435-9237
    </div>

    <div class="container" v-if="digitalCertificate">
      <div v-if="!showFullPageAd" class="right">
        <video
          ref="videoRef"
          class="dig-cert-vid"
          :src="digitalCertificateVideoURL"
          autoplay
          muted
          playsinline
          @play="handleCertificateNumber"
          @timeupdate="handleTime"
          @ended="handleVideoPlayback"
        ></video>
        <div
          v-if="showDISCert"
          class="certificate-number-wrapper"
          :class="{
            hz_settings: isHZ,
            isTemplate4: isTemplate4,
            has_footer_ad: has_footer_ad,
            has_imperfections: has_imperfections,
          }"
        >
          <strong>
            DIS CERTIFICATE#:
            {{
              digitalCertificate &&
              digitalCertificate.ManufacturedAs === "Lab Grown"
                ? `LG${digitalCertificate.CertNum}`
                : $route.params.certificate_num
            }}</strong
          >
        </div>
      </div>
      <div
        v-if="
          $route.params.type.includes('diamond') &&
          imperfection.display_imperfection_icons
        "
        class="imperfection_img"
      >
        <img
          class="img"
          :src="imperfection.url"
          alt="Imperfection Template"
          ref="characteristics_url"
        />
        <div class="imperfections_key_holder">
          <p class="imperfection_chosen_text section_two_section_head">
            Key to Symbols
          </p>

          <div
            v-if="
              imperfection.imperfectionAddedList &&
              imperfection.imperfectionAddedList.length
            "
            class="key_info_holder"
          >
            <template>
              <div
                class="imperfections_key"
                v-for="(
                  imperfection, index
                ) in imperfection.imperfectionAddedList"
                :key="index"
              >
                <img
                  ref="imperfection_url"
                  class="imperfection_img_icon"
                  :src="
                    require('@/assets/img/imperfections/' + imperfection.image)
                  "
                  alt="imperfection"
                />
                <p class="imperfections_key_text">{{ imperfection.name }}</p>
              </div>
            </template>
            <div
              v-if="!imperfection.display_imperfection_icons"
              class="imperfections_key_noicon"
            >
              <p>
                Red Symbols indicate internal characteristics. <br />
                Green symbols indicate external characteristics
              </p>
            </div>
          </div>
        </div>
      </div>

      <transition name="fade" mode="out-in">
        <ad-page
          v-if="activateAds"
          :currentCampaign="currentCampaign"
          :showFullPageAd="showFullPageAd"
          :showFooterAd="showFooterAd"
          @handleAdToggle="handleAdToggle"
          @handle-analytics="handleAnalytics('click', true)"
        ></ad-page>
      </transition>
    </div>

    <div class="nocontent" v-if="noCertificate && !DISLoading">
      Virtual Certificate Video Does Not Exist or Hasn't been Uploaded Yet.
      {{ `\n` }} Redirecting you back to the home page in ...{{ redirectTimer }}
    </div>
  </div>
</template>

<script>
import { db, functions } from "@/firebaseConfig.js";
import AdPage from "@/components/AdPage.vue";
const { DateTime } = require("luxon");
import axios from "axios";

export default {
  name: "DigitalCertificate",
  components: {
    "ad-page": AdPage,
  },
  data() {
    return {
      imperfection: {},
      digitalCertificate: null,
      digitalCertificateVideo: null,
      digitalCertificateVideoURL: null,
      skuCertificate: false,
      showShopBtn: false,
      noCertificate: false,
      showFullPageAd: false,
      showFooterAd: false,
      showDISCert: false,
      isHZ: false,
      isTemplate4: false,
      olderCertificate: false,
      noAdsInit: false,
      activateAds: false,
      campaigns: [],
      currentCampaign: {},
      stolenItem: false,
      DISLoading: true,
      DISLogo: require("../../public/img/loading.gif"),
      has_imperfections: false,
      has_footer_ad: false,
      redirectTimer: 3,
      location: null,
      activeAnalytics: false,
      enterTimestamp: null,
      analyticsDoneOnClick: false,
      initAnalytics: false,
      timerTickerBeforeAd: 20,
    };
  },

  async created() {
    // await this.fetchIPAddress();
    this.enterTimestamp = Date.now();

    this.fetchJewelryData(
      this.$route.params.certificate_num.trim(),
      this.$route.params.type
    );
  },

  methods: {
    async getAttributes(key, att) {
      let doc = await db.doc(`attributes/${att}`).get();
      if (doc.exists) {
        doc = doc.data();
      }
      this[key] = doc;
    },
    async goToShop() {
      await this.handleAnalytics("click", true);
      this.analyticsDoneOnClick = true;
      this.$router.push({
        name: "Redirect",
        params: {
          to: "https://www.helzberg.com/",
        },
      });
    },
    fetchUserDevice() {
      const userAgent = navigator.userAgent;
      if (/Android/i.test(userAgent)) {
        console.log("This is an Android device");
        return "Android";
      } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
        console.log("This is an iOS device");
        return "iOS";
      } else {
        console.log("This is a desktop computer");
        return "Desktop";
      }
    },
    // async fetchUserLocation() {
    //   const getCoordinates = (position) => {
    //     fetch(
    //       `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyB8hTXyIpTHInsiRvQYhu26LkCf9SNjSkA`
    //     )
    //       .then((response) => response.json())
    //       .then((data) => {
    //         const country = data.results[0].address_components.find(
    //           (component) => component.types.includes("country")
    //         ).long_name;
    //         const locality = data.results[0].address_components.find(
    //           (component) => component.types.includes("locality")
    //         ).long_name;

    //         this.location = { country, locality };
    //       })
    //       .catch((error) => console.error(error));
    //     this.activeAnalytics = true;
    //   };
    //   if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition(getCoordinates);
    //   } else {
    //     this.activeAnalytics = true;
    //   }
    // },
    async handleAnalytics(userAction, saveViewingTime) {
      if (this.initAnalytics) {
        const viewingTime = this.handleViewingTime();
        const clientId = this.client.id;
        const userDevice = this.fetchUserDevice();
        const productID =
          this.digitalCertificate.ClientSKU ||
          this.digitalCertificateVideo.id ||
          this.digitalCertificate.CertNum;
        const country = this.location ? this.location.country : "United States";
        const locality = this.location ? this.location.locality : null;
        const certificateData = this.digitalCertificate;
        const handleAnalyticsPerCertificate = functions.httpsCallable(
          "analytics-handleAnalyticsPerClient"
        );

        try {
          let data = await handleAnalyticsPerCertificate({
            clientId,
            userDevice,
            productID,
            country,
            locality,
            viewingTime,
            saveViewingTime,
            userAction,
            certificateData,
          });
          console.log(data);
          return true;
        } catch (error) {
          console.log(error);
          return true;
        }
      }
    },
    async fetchImperfections(id) {
      let imperfection_doc = await db.doc(`diamond_imperfections/${id}`).get();

      if (imperfection_doc.exists) {
        this.imperfection = Object.assign(
          { id: imperfection_doc.id },
          imperfection_doc.data()
        );
        this.has_imperfections = true;
      }
    },
    handleCertificateNumber() {
      if (this.skuCertificate) {
        const seconds = this.isHZ ? 4000 : 3200;
        setTimeout(() => {
          this.showDISCert = true;
        }, seconds);
      }
    },
    //For Advertisement
    handleTime(e) {
      if (!this.noAdsInit) {
        if (e.target.currentTime > this.timerTickerBeforeAd.values) {
          this.handleVideoPlayback();
        }
      }
    },
    handleVideoPlayback() {
      if (this.campaigns.length) {
        this.showFullPageAd = true;
        this.showFooterAd = false;
      } else {
        if (this.skuCertificate) this.showDISCert = false;
        this.$refs.videoRef.play();
      }
    },
    async fetchJewelryData(id, type) {
      let digitalCertificate;

      if (type !== "only-sku") {
        let jewelryRef = await db.doc(`${type}/${id}`).get();

        if (!jewelryRef.exists) {
          jewelryRef = await db
            .collection(type)
            .where("CertNum", "==", id)
            .get();
          if (jewelryRef.empty) {
            this.noCertificate = true;
            this.digitalCertificate = null;
            this.DISLoading = false;
            return false;
          }
          digitalCertificate = jewelryRef.docs[0].data();
        } else {
          digitalCertificate = jewelryRef.data();
        }
        this.isHZ = digitalCertificate.Company.name.includes("Helzberg");
        this.isTemplate4 = digitalCertificate.Template.id === 4;

        if (digitalCertificate.isStolen) {
          this.stolenItem = true;
          this.digitalCertificate = false;
          this.DISLoading = false;
          return false;
        }

        this.digitalCertificate = digitalCertificate;
        await this.fetchdigitalCertificateVideoURL(
          digitalCertificate.ClientSKU,
          digitalCertificate.CertNum,
          digitalCertificate.Company,
          false
        );

        if (type == "diamond") {
          if (digitalCertificate.imperfection)
            await this.fetchImperfections(digitalCertificate.imperfection.id);
        }

        setTimeout(() => {
          this.handleAnalytics("view", false);
        }, 2000);

        setTimeout(async () => {
          await this.fetchClientCampaign();
          this.initAnalytics = await this.handleAnalyticsInitilization(
            this.digitalCertificate
          );
        }, 1000);
      } else {
        await this.fetchdigitalCertificateVideoURL("", id, "", true);
        this.digitalCertificate = Object.assign({});
      }

      // setTimeout(() => {
      //   this.DISLoading = false;
      // }, 1000);
    },
    async fetchClientCampaign() {
      const timestamp = DateTime.now().toMillis();
      const campaigns = await db
        .collection(`companies/${this.digitalCertificate.Company.id}/campaigns`)
        .where("active", "==", true)
        .orderBy("start_at", "asc")
        .endAt(timestamp)
        .get();

      if (!campaigns.empty) {
        this.campaigns = campaigns.docs
          .map((d) => Object.assign({ id: d.id }, d.data()))
          .filter((d) => {
            if (d.use_for_all) {
              return true;
            } else if (d.include_sku.selected) {
              return (
                d.include_sku.items.includes(this.digitalCertificate.CertNum) ||
                d.include_sku.items.includes(this.digitalCertificate.ClientSKU)
              );
            } else if (d.exclude_sku.selected) {
              return (
                !d.exclude_sku.items.includes(
                  this.digitalCertificate.CertNum
                ) ||
                !d.exclude_sku.items.includes(this.digitalCertificate.ClientSKU)
              );
            } else if (
              this.digitalCertificate.hasOwnProperty("Customer") &&
              d.fetch_by_customer &&
              d.fetch_by_customer.selected
            ) {
              return this.digitalCertificate.Customer.includes(
                d.fetch_by_customer.value
              );
            } else {
              return false;
            }
          });

        if (this.campaigns.length) {
          const randomCampaign = Math.floor(
            Math.random() * this.campaigns.length
          );
          this.currentCampaign = this.campaigns[randomCampaign];

          setTimeout(() => {
            this.activateAds = Boolean(this.campaigns.length);
            setTimeout(() => {
              this.showFooterAd = Boolean(this.currentCampaign.footer_ad.url);
              if (this.currentCampaign.footer_ad.url) this.has_footer_ad = true;
            }, 500);
          }, 2000);
        } else {
          this.noAdsInit = true;
        }
      } else {
        this.noAdsInit = true;
      }
    },
    async fetchdigitalCertificateVideoURL(sku, certNum, client, onlySKU) {
      this.client = client;
      let showCertNumber = true;
      let certificateVideo;
      try {
        certificateVideo = await db
          .doc(`digital_certificate_videos/${certNum}`)
          .get();

        if (!certificateVideo.exists) {
          certificateVideo = await db
            .doc(`digital_certificate_videos/${sku}`)
            .get();

          if (!certificateVideo.exists) {
            this.DISLoading = false;
            this.noCertificate = true;
            return false;
          }
        }

        certificateVideo = Object.assign(
          { id: certificateVideo.id },
          certificateVideo.data()
        );

        if (certificateVideo.globalSku) {
          showCertNumber = true;
        }

        this.digitalCertificateVideo = certificateVideo;
        this.digitalCertificateVideoURL = certificateVideo.meta.url;
        this.skuCertificate = showCertNumber;

        if (onlySKU) {
          this.skuCertificate = false;
        }
        this.isHZ = client ? client.name.includes("Helzberg") : false;
      } catch (error) {
        // console.error("Error: ", error);
        if (this.digitalCertificate.CertificateVideo) {
          this.olderCertificate = true;
          this.digitalCertificateVideo =
            this.digitalCertificate.CertificateVideo;
          this.digitalCertificateVideoURL =
            this.digitalCertificate.CertificateVideo.url;
        } else {
          this.noCertificate = true;
        }
        // console.error(error);
        // try {
        //   const storageRef = storage.ref();
        //   const videoRef = storageRef.child(
        //     `/certificate_videos/${this.$route.params.certificate_num}`
        //   );

        //   const url = await videoRef.getDownloadURL();
        //   this.digitalCertificateVideoURL = url;
        //   this.skuCertificate = false;
        //   this.isHZ = client.includes("Helzberg");
        // } catch (error) {
        //   // console.error(error);
        //   if (this.digitalCertificate.CertificateVideo) {
        //     this.digitalCertificateVideoURL =
        //       this.digitalCertificate.CertificateVideo.url;
        //   } else {
        //     this.noCertificate = true;
        //   }
        // }
      }
    },
    handleAdToggle(data) {
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          this[key] = data[key];
        }
      }
      this.showDISCert = false;
    },
    handleViewingTime() {
      const leaveTimestamp = Date.now();
      const timeSpentOnSite = leaveTimestamp - this.enterTimestamp; // Time in milliseconds
      // You can convert the time to seconds, minutes, or hours as needed
      const secondsSpent = Math.floor(timeSpentOnSite / 1000); // Convert milliseconds to seconds
      const minutesSpent = Math.floor(secondsSpent / 60); // Convert seconds to minutes

      console.log({ seconds: secondsSpent, minutes: minutesSpent });
      return { seconds: secondsSpent, minutes: minutesSpent };
    },
    async fetchIPAddress() {
      try {
        const IPAdd = await axios.get("https://api.ipify.org?format=json");
        return IPAdd.data.ip === process.env.VUE_APP_DIS_IP;
      } catch (error) {
        return false;
      }
    },

    async handleAnalyticsInitilization(data) {
      const timestampDate = DateTime.fromMillis(data.created).startOf("day");
      const now = DateTime.local().startOf("day");
      const isSystemIP = await this.fetchIPAddress();
      if (isSystemIP) return false;
      return !timestampDate.equals(now);
    },
  },
  watch: {
    activateAds(val) {
      if (val) {
        this.getAttributes("timerTickerBeforeAd", "timerTickerBeforeAd");
      }
    },
    digitalCertificateVideoURL() {
      this.DISLoading = false;
    },
    noCertificate(e) {
      if (e) {
        setInterval(() => {
          this.redirectTimer -= 1;
        }, 3000);
      }
    },
    redirectTimer(e) {
      if (!e) {
        window.location = "https://diamondservicesusa.com/";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.link-btn {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  top: 30%;
}
.has_imperfections {
  top: 29.5% !important;
}

.has_footer_ad {
  margin-top: -11.5% !important;
}

.loader {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.loader img {
  max-width: 100%;
  max-height: 100%;
}

.imperfection_img {
  // position: absolute;
  width: 100%;
  top: 50em;

  .img {
    // background: #2b2b2b;
    width: 100%;
    z-index: 2;
    position: relative;
    // left: 2px;
    filter: invert(1);
  }

  .img_background {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
    filter: none;
  }

  .imperfections_key_holder {
    margin-left: 0;
    padding-left: 15px;
    color: white;
    background: black;
    padding-left: 5%;
    padding-bottom: 2%;
  }

  .imperfection_chosen_text {
    margin-left: 0;
    font-size: 18px;
    width: 100%;
    margin-top: 0;
    margin-bottom: 5px;
  }

  .key_info_holder {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .imperfections_key {
      margin: 0;
      width: auto;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      margin-right: 10px;
      margin-bottom: 5px;

      p {
        font-size: 18px;
        margin: 0;
      }

      img {
        height: 20px;
        margin-left: 3px;
        filter: invert(1);
      }
    }
  }
}
.stolen-message {
  width: 100vw;
  height: 100vh;
  text-align: center;
  font-size: 45px;
  padding-top: 125px;
  overflow: hidden;
  text-transform: uppercase;
}

.btn {
  width: 130px;
  background-color: #6c083d;
  border-radius: 9px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: white;
  border: 0.5px dashed;
  animation: popup 1s ease-in;
  cursor: pointer;
}

@keyframes popup {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes breathe {
  0%,
  100% {
    transform: scale(0.2);
  }
  50% {
    transform: scale(1.1);
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.certificate-number-wrapper {
  position: absolute;
  width: 100%;
  color: white;
  text-align: center;
  top: 40%;
  font-family: "Montserrat";
  animation: blink 0.2s 1;
  font-weight: bolder;
  font-size: 82%;
  right: 2px;
}

.hz_settings {
  color: #7e1d47 !important;
  top: 37% !important;
  font-weight: bolder;
}

.isTemplate4 {
  top: 36.5% !important;
}
.noAds {
  top: 37% !important;
}
.home {
  overflow: hidden;
}

.nocontent {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 43%;
  height: 500px;
  font-size: 25px;
  color: white;
  display: flex;
  align-content: center;
  align-items: center;
  overflow: hidden;
}

body {
  margin: 0;
  overflow: hidden;
}

.complete {
  opacity: 1 !important;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-width: 1000px) {
  .certificate-number-wrapper {
    top: 37.5% !important;
    font-size: 78%;
  }

  .isTemplate4 {
    top: 36.5% !important;
  }

  .hz_settings {
    top: 35% !important;
  }

  .has_footer_ad {
    margin-top: 0% !important;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 400px) {
  .certificate-number-wrapper {
    font-size: 67%;
  }

  // .hz_settings {
  //   top: 39% !important;
  // }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-width: 400px) and (min-height: 800px) and (max-height: 900px) {
  .certificate-number-wrapper {
    font-size: 78%;
  }

  // .hz_settings {
  //   top: 39% !important;
  // }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-width: 375px) and (max-width: 400px) and (min-height: 800px) and (max-height: 900px) {
  .certificate-number-wrapper {
    font-size: 75%;
  }

  // .hz_settings {
  //   top: 39% !important;
  // }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-width: 350px) and (max-width: 380px) and (max-height: 820px) {
  .certificate-number-wrapper {
    font-size: 72%;
  }

  // .hz_settings {
  //   top: 39% !important;
  // }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-width: 350px) and (max-width: 380px) and (max-height: 815px) {
  .certificate-number-wrapper {
    font-size: 72%;
  }

  // .hz_settings {
  //   top: 39% !important;
  // }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-width: 350px) and (max-width: 380px) and (max-height: 670px) {
  .certificate-number-wrapper {
    font-size: 72%;
  }

  // .hz_settings {
  //   top: 39% !important;
  // }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-width: 350px) and (max-width: 380px) and (min-height: 700px) and (max-height: 760px) {
  .certificate-number-wrapper {
    font-size: 67%;
  }

  // .hz_settings {
  //   top: 39% !important;
  // }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-width: 350px) and (max-width: 380px) and (max-height: 650px) {
  .certificate-number-wrapper {
    font-size: 56%;
  }

  // .hz_settings {
  //   top: 39% !important;
  // }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-width: 400px) and (max-width: 450px) and (min-height: 800px) and (max-height: 900px) {
  .certificate-number-wrapper {
    font-size: 79%;
  }

  // .hz_settings {
  //   top: 39% !important;
  // }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-width: 400px) and (min-height: 800px) and (max-height: 850px) {
  .certificate-number-wrapper {
    font-size: 75%;
  }

  // .hz_settings {
  //   top: 39% !important;
  // }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 400px) and (max-height: 850px) {
  // .hz_settings {
  //   top: 39% !important;
  // }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 400px) and (max-height: 599px) {
  .certificate-number-wrapper {
    font-size: 8px !important;
  }

  // .hz_settings {
  //   top: 39% !important;
  // }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 400px) and (min-height: 599px) and (max-height: 700px) {
  // .hz_settings {
  //   top: 39% !important;
  // }
}

//iPhones 6/7/8 ios 11
@media screen and (-webkit-min-device-pixel-ratio: 0) and (width: 375px) and (height: 667px) {
  .certificate-number-wrapper {
    font-size: 59% !important;
  }

  // .hz_settings {
  //   top: 39% !important;
  // }
}

//iPhones 6/7/8 PRO ios 11
@media screen and (-webkit-min-device-pixel-ratio: 0) and (width: 414px) and (height: 736px) {
  .certificate-number-wrapper {
    font-size: 65% !important;
  }

  // .hz_settings {
  //   top: 39% !important;
  // }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 300px) and (max-height: 900px) {
  .certificate-number-wrapper {
    font-size: 12px !important;
  }

  // .hz_settings {
  //   top: 39% !important;
  // }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-width: 800px) and (min-height: 1000px) {
  .certificate-number-wrapper {
    font-size: 18px !important;
  }
  // .hz_settings {
  //   top: 39% !important;
  // }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 420px) and (height: 846px) {
  // .hz_settings {
  //   top: 39% !important;
  // }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 400px) and (min-height: 850px) {
  .certificate-number-wrapper {
    font-size: 75% !important;
  }
  // .hz_settings {
  //   top: 39% !important;
  // }
}
</style>
